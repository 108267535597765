var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "landing-central-card",
    [
      _c(
        "v-card",
        {
          staticClass: "federated_end-card flexcard",
          class: _vm.$vuetify.breakpoint.smAndUp ? "pa-3" : "pa-0",
          style: _vm.cardStyles,
          attrs: {
            flat: "",
            elevation: _vm.elevation,
            "min-height": _vm.cardHeight
          }
        },
        [
          _vm.loading ? _c("v-card-loader") : _vm._e(),
          _c(
            "v-card-text",
            { staticClass: "pa-0" },
            [
              _c(
                "v-layout",
                {
                  attrs: {
                    row: "",
                    wrap: "",
                    "justify-center": "",
                    "gap-xs-3": ""
                  }
                },
                [
                  _vm.loading
                    ? [
                        _c(
                          "v-flex",
                          {
                            attrs: {
                              xs12: "",
                              sm11: "",
                              md10: "",
                              "text-center": ""
                            }
                          },
                          [
                            _c("h1", { staticClass: "starling-h1" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("public.federated.end.loading.title")
                                )
                              )
                            ])
                          ]
                        ),
                        _c(
                          "v-flex",
                          {
                            attrs: {
                              xs12: "",
                              sm11: "",
                              md10: "",
                              "text-center": ""
                            }
                          },
                          [
                            _c("p", { staticClass: "starling-body" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("public.federated.end.loading.content")
                                )
                              )
                            ])
                          ]
                        )
                      ]
                    : [
                        !_vm.error
                          ? [
                              _c(
                                "v-flex",
                                { attrs: { xs8: "", sm5: "" } },
                                [
                                  _vm.animation && _vm.animation.script
                                    ? _c("lottie-player", {
                                        ref: "animationPlayer",
                                        attrs: {
                                          src: _vm.animation.script,
                                          autoplay: _vm.animation.autoplay,
                                          count: _vm.animation.count,
                                          direction: _vm.animation.direction,
                                          hover: _vm.animation.hover,
                                          loop: _vm.animation.loop,
                                          mode: _vm.animation.mode,
                                          speed: _vm.animation.speed
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                {
                                  attrs: {
                                    xs12: "",
                                    sm11: "",
                                    md10: "",
                                    "text-center": ""
                                  }
                                },
                                [
                                  _c("h1", { staticClass: "starling-h1" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "public.federated.end.success.title"
                                        )
                                      )
                                    )
                                  ])
                                ]
                              ),
                              _c(
                                "v-flex",
                                {
                                  attrs: {
                                    xs12: "",
                                    sm11: "",
                                    md10: "",
                                    "text-center": ""
                                  }
                                },
                                [
                                  _c("p", { staticClass: "starling-body" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "public.federated.end.success.content"
                                        )
                                      )
                                    )
                                  ])
                                ]
                              )
                            ]
                          : [
                              _c(
                                "v-flex",
                                {
                                  attrs: { xs8: "", sm5: "", "text-center": "" }
                                },
                                [_c("registration-error-image")],
                                1
                              ),
                              _c(
                                "v-flex",
                                {
                                  attrs: {
                                    xs12: "",
                                    sm11: "",
                                    md10: "",
                                    "text-center": ""
                                  }
                                },
                                [
                                  _c("h1", { staticClass: "starling-h1" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "public.federated.end.error.title"
                                        )
                                      )
                                    )
                                  ])
                                ]
                              ),
                              _c(
                                "v-flex",
                                {
                                  attrs: {
                                    xs12: "",
                                    sm11: "",
                                    md10: "",
                                    "text-center": ""
                                  }
                                },
                                [
                                  _c("p", { staticClass: "starling-body" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "public.federated.end.error.content"
                                        )
                                      )
                                    )
                                  ])
                                ]
                              )
                            ]
                      ]
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }